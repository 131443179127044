import React, { useState, useEffect } from "react";

import { useAuth, ALSUser } from "@api/auth";

import { prefetch } from "@utils/prefetch";

import { Splash } from "~/components/Splash";
import { Redirect } from "~/components/Redirect";

import type {
	IRedirectTimer,
	ISecurityRedirectProps,
	SecurityRedirectPath,
} from "./types";

const SecurityRedirect: React.FC<ISecurityRedirectProps> = ({
	to,
	...redirectProps
}) => {
	const { user, loading } = useAuth();
	const [redirectPath, setRedirectPath] = useState("");
	const [timer] = useState<IRedirectTimer>(() => {
		const timeMounted = new Date();
		const maxTime = 1000;
		return {
			start(to: SecurityRedirectPath, user?: ALSUser) {
				const curTime = new Date();
				const timeDiff = curTime.valueOf() - timeMounted.valueOf();
				const navPath = typeof to === "function" ? to(user) : to;
				prefetch(navPath);
				const doRedirect = () => {
					setRedirectPath(navPath);
				};
				if (timeDiff < maxTime) {
					this.timeout = setTimeout(doRedirect, maxTime - timeDiff);
				} else {
					doRedirect();
				}
			},
			cancel() {
				if (this.timeout) clearTimeout(this.timeout);
				this.timeout = undefined;
			},
		};
	});

	useEffect(() => {
		if (loading) return;
		timer.start(to, user);
		return () => {
			timer.cancel();
		};
	}, [timer, user, loading, to]);

	return (
		<Splash
			Redirect={
				!!redirectPath && (
					<Redirect {...redirectProps} to={redirectPath} />
				)
			}
		/>
	);
};

export default SecurityRedirect;
